<template>
  <div>
    <v-card
      class="overflow-hidden mb-5"
    >
      <v-card-title class="d-flex justify-space-between">
        <h4>
          Créer une certification
        </h4>
        <v-icon
          top
          right
        >
          {{ icons.mdiCertificateOutline }}
        </v-icon>
      </v-card-title>
      <v-divider>
      </v-divider>
      <v-form ref="form">
        <v-card-text class="mt-3">
          <v-row>
            <v-col>
              <v-text-field
                v-model="certification.name"
                label="Nom de la certification"
                outlined
                dense
                placeholder="Nom de la certification"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="certification.certifier"
                label="Organisme certifiant"
                outlined
                dense
                placeholder="ETS Global"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="certification.cost"
                type="number"
                label="Coût de la certification"
                outlined
                suffix="€"
                dense
                placeholder="150"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between">
          <v-btn
            type="reset"
            outlined
            class="mx-2"
          >
            Réinitialiser
          </v-btn>
          <v-btn
            color="primary"
            @click="createCertification"
          >
            Créer
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mdiCertificateOutline } from '@mdi/js'

export default {
  metaInfo: {
    title: 'Créer une certification',
    titleTemplate: '%s - Jimbo',
  },
  components: {
  },
  data() {
    return {
      certification: {
        company: this.$store.state.company._id,
        name: '',
        cost: 0,
        certifier: '',
      },
      icons: {
        mdiCertificateOutline,
      },
    }
  },
  mounted() {
  },
  methods: {
    createCertification() {
      this.$http.post(`${process.env.VUE_APP_API_URL}/certification/new`, this.certification)
        .then(() => {
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Certification crée avec succcès',
            value: true,
          })
          this.$refs.form.reset()
        }).catch(err => {
          console.log(err)
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la création de la certficiation ',
            value: true,
          })
        })
    },
  },
}
</script>

<style>

</style>
